import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '@models/api-response';
import { IStudentProfile } from '@models/user/profile-detail';
import { IUserStatusPartial } from '@models/user/user-status';
import { of, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../environments/environment';

@Injectable()
export class AuthService {
  constructor(private http: HttpClient) { }

  private getStudentProfileUrl(): string {
    return `${environment.apiUrl}/student/profile`;
  }

  getUserStatus(): Observable<ApiResponse<IUserStatusPartial>> {
    const url = `${environment.apiUrl}/user/status?isBase=true`;
    return this.http.get<ApiResponse<IUserStatusPartial>>(url)
      .pipe(
        map(res => new ApiResponse<IUserStatusPartial>(true, res)),
        catchError((err: HttpErrorResponse) => {
          console.error(err);
          return of(new ApiResponse<IUserStatusPartial>(false, err.error));
        })
      );
  }

  acceptAssessmentVideo(name: string): Observable<ApiResponse<boolean>> {
    return this.http.patch(this.getStudentProfileUrl(), {
      AcceptedInstruction:
      {
        Name: name,
        InstructionType: 'AssessmentVideo'
      }
    })
      .pipe(
        map(() => new ApiResponse<boolean>(true, { response: true, messages: [] })),
        catchError((err: HttpErrorResponse) => {
          console.error(err);
          return of(new ApiResponse<boolean>(false, err.error));
        })
      );
  }

  getStudentProfile(): Observable<ApiResponse<IStudentProfile>> {
    return this.http.get<ApiResponse<IStudentProfile>>(this.getStudentProfileUrl())
      .pipe(
        map(res => new ApiResponse<IStudentProfile>(true, res)),
        catchError((err: HttpErrorResponse) => {
          console.error(err);
          return of(new ApiResponse<IStudentProfile>(false, err.error));
        })
      );
  }
}
