export enum ChallengeType {
  Pretest = 'Pretest',
  Posttest = 'Posttest',
  Test = 'Test'
}

export enum ChallengeTypeNumeric {
  Pretest = 0,
  Posttest = 1,
  Test = 2
}
