export enum VariantType {
  GradePreK = 'GradePreK',
  GradeK = 'GradeK',
  Grade1 = 'Grade1',
  Grade2 = 'Grade2',
  Grade2C = 'Grade2C',
  Grade2M = 'Grade2M',
  Grade3 = 'Grade3',
  Grade4 = 'Grade4',
  Grade5 = 'Grade5',
  Grade6 = 'Grade6',
  Grade7 = 'Grade7',
  Grade8 = 'Grade8',
  Grade9 = 'Grade9',
  Grade10 = 'Grade10',
  Grade11 = 'Grade11',
  Grade12 = 'Grade12',
  Apprentice = 'Apprentice',
  ENAC = 'ENAC',
  ENACSpanish = 'ENACSpanish',
  GradeKto2 = 'GradeKto2',
  GradeKto2Spanish = 'GradeKto2',
  Grade3to5 = 'Grade3to5',
  Grade3to5Spanish = 'Grade3to5',
  OnlineTools = 'OnlineTools',
  Specialist = 'Specialist',
  StagesABC = 'StagesABC',
  StagesABCSpanish = 'StagesABCSpanish',
  StageD = 'StageD',
  Recruit = 'Recruit',
  SimplifiedCoreResources = 'SimplifiedCoreResources',
  None = '',
}

export enum VariantTypeIdentifier {
  _gpk = 'GradePreK',
  _gk = 'GradeK',
  _g1 = 'Grade1',
  _g2 = 'Grade2',
  _g3 = 'Grade3',
  _g4 = 'Grade4',
  _g5 = 'Grade5',
  _g6 = 'Grade6',
  _g7 = 'Grade7',
  _g8 = 'Grade8',
  _g9 = 'Grade9',
  _g10 = 'Grade10',
  _g11 = 'Grade11',
  _g12 = 'Grade12',
  _a = 'Apprentice',
  _enac = 'ENAC',
  _enaces = 'ENACSpanish',
  '_gk-2' = 'GradeKto2',
  '_gk-2es' = 'GradeKto2Spanish',
  _g2c = 'Grade2C',
  _g2m = 'Grade2M',
  '_g3-5' = 'Grade3to5',
  '_g3-5es' = 'Grade3to5Spanish',
  _wm = 'OnlineTools',
  _s = 'Specialist',
  _abc = 'StagesABC',
  _abces = 'StagesABCSpanish',
  _d = 'StageD',
  _r = 'Recruit',
  _sc = 'SimplifiedCoreResources',
}
