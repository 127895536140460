/** enumeration represents the products represented in digital resources */
export enum Products {
  spelling = 'spelling',
  handwriting = 'handwriting',
  superkids = 'superkids',
  ece = 'ece',
  gum = 'gum',
  jiw = 'jump-into-writing',
  irtw = 'i-read-to-write',
  kick = 'kickstart',
  ms = 'mindscapes',
  wordh = 'word-heroes',
  wh = 'word-heroes',
  ww = 'word-wisdom',
}
