import { IUser } from '../user';
import { IUserProfileDetail } from './profile-detail';

import { RoleType } from './role-type';
import { IUserSetting } from './user-setting';

export class ApplicationUser implements IUser {
  userId: string;
  firstName: string;
  lastName: string;
  roleTypes: RoleType[] = [];
  settings: IUserSetting[] = [];
  profileDetail: IUserProfileDetail;

  private _userName: string;
  private _studentUserName: string;

  get username(): string {
    if (this.isStudent && this._studentUserName) {
      return this._studentUserName;
    }
    return this._userName;
  }

  get isStudent(): boolean {
    if (this.profileDetail?.viewingAsRole) {
      return this.profileDetail.viewingAsRole === RoleType.Student;
    }

    return this.roleTypes.includes(RoleType.Student);
  }

  get isTeacher(): boolean {
    if (this.profileDetail?.viewingAsRole) {
      return this.profileDetail.viewingAsRole === RoleType.Teacher;
    }

    return this.roleTypes.includes(RoleType.Teacher);
  }

  get isParent(): boolean {
    if (this.profileDetail?.viewingAsRole) {
      return this.profileDetail.viewingAsRole === RoleType.Parent;
    }

    return this.roleTypes.includes(RoleType.Parent);
  }

  get isSchoolAdmin(): boolean {
    if (this.profileDetail?.viewingAsRole) {
      return this.profileDetail.viewingAsRole === RoleType.SchoolAdministrator
        || this.profileDetail.viewingAsRole === RoleType.DistrictAdministrator;
    }

    return this.roleTypes.includes(RoleType.SchoolAdministrator)
      || this.roleTypes.includes(RoleType.DistrictAdministrator);
  }

  constructor(values: {[key: string]: any}) {
    this.userId = values.userId ? values.userId : null;
    this.firstName = values.firstName ? values.firstName : null;
    this.lastName = values.lastName ? values.lastName : null;
    this.settings = values.settings ? values.settings : null;
    this.roleTypes = values.roleTypes ? values.roleTypes : [];

    this._userName = values.username ? values.username : '';
    this._studentUserName = values.studentUserName ? values.studentUserName : '';

    this.profileDetail = values.profileDetail ? values.profileDetail : null;
  }
}
