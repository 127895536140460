import { GradeType } from '@models/grade-type';
import { ProductType } from '@models/product-type';
import { VariantType, VariantTypeIdentifier } from '@models/variant-type';
import { ViewerLanguage } from '@models/viewer-meta';
import { Word } from '@models/word';

/**
 * Contains functions that help streamline the processing of HTTP responses
 */
export namespace Helpers {

  export const componentIdFullPattern: RegExp = /^([a-z]{2,})([0-9]{4})([a-z]{0,})_(g[k0-9]-[k0-9]|g[0-9pk]+|abc|d|enac|sc)([a-z]{0,})_?.*$/i;
  export const componentIdVariantPattern: RegExp = /^([a-z]{2,})([0-9]{4})([a-z]{0,})_(g[k0-9]-[k0-9]|g[0-9pk]+|abc|d|enac|sc)(es|m|c)?/i;
  export const componentIdProductLinePattern: RegExp = /^([a-z]{2,})([0-9]{4})([a-z]{0,})/i;
  export const variantPattern: RegExp = /_(g[k0-9]-[k0-9]|g[pk0-9]+|abc|d|enac|sc)/i;
  export const topicKeyPattern: RegExp = /^[a-z0-9]+_(pretest|test|posttest|practice|Wr|Le|Ga|Pr|Pa|Ex|Wl|Ws|Fw|Tr)(G[pk0-9]-[k0-9]|G[pk0-9]+[a-z]?|abc|d|enac|sc)U([0-9R]+)/i;

  /**
   * Transforms a key value object (where key is a string or number and the value is of type T)
   * into an array of type T
   *
   * @export
   * @template T
   * @param {{ [id: string]: T }} dictionary
   * @returns {Array<T>}
   */
  export function mapDictionaryToArray<T>(dictionary: { [id: string]: T }): Array<T> {
    const array: T[] = Object
      .keys(dictionary)
      .filter(key => Object.prototype.hasOwnProperty.call(dictionary, key))
      .map(key => dictionary[key]);
    return array;
  }

  export function capitalizeFirstLetter(word: string): string {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  /**
   * Takes a string and trys to parse it to an object, otherwise returns null.
   *
   * @export
   * @param {string} jsonString
   * @returns {Object}
   */
  export function tryParseJSON(jsonString: string): Object {
    try {
      const o = JSON.parse(jsonString);
      if (o && o instanceof Object) {
        return o;
      }
    } catch (e) {
      console.error(e);
    }
    return null;
  }

  export function logWordList(array: Array<Word>): void {
    console.log('Word List ----->');
    array.forEach(w => console.log(w.word));
    console.log('<-----');
  }

  /** gets the full <base href=""> path */
  export function getBaseUrl(): string {
    return (document.getElementsByTagName('base')[0] || { href: '' }).href;
  }

  /** replaces whitespaces characters in word string */
  export function normalizeWordFilename(word: string): string {
    return word.replace(/ /g, '_').replace('\'', '_').replace('.', '').replace('"', '');
  }

  /** checks a keyboard event to see if it's triggered by enter key. */
  export function isEnterKey(event: KeyboardEvent) {
    return event.keyCode === 13;
  }

  /** gets GradeType enum for a given grade string */
  export function mapGradeToGradeType(grade: string): GradeType {
    const key = `Grade${grade?.toUpperCase()}`;
    if (key != null && GradeType[key]) {
      return GradeType[key];
    }
    if (grade === 'pk') {
      return GradeType.GradePreK;
    }
    return GradeType.None;
  }

  export function mapGradeTypeToGrade(value: GradeType): string {
    if (value) {
      if (value === GradeType.GradePreK) {
        return 'Pre-K';
      }

      const matches = value.toString().match(/([K0-9]+)$/i);
      if (matches) {
        return matches[1];
      }
      return value === GradeType.None ? 'None' : '';
    }

    return 'N';
  }

  export function extractGradeKeyFromTopicKey(topicKey: string): string {
    const matches = topicKey.match(/G([0-9PpKk]{1,2}[MC]?)/);
    if (matches && matches.length > 1) {
      return matches[1];
    }
    return null;
  }

  export function mapGradeKeyToVariantKey(gradeKey: string): string {
    if (gradeKey) {
      return gradeKey === 'PK' ? 'GradePreK' : `Grade${gradeKey.toLocaleUpperCase()}`;
    }
    return 'None';
  }

  export function isGumProduct(productType: ProductType): boolean {
    return productType === ProductType.DigitalResourcesGum2021;
  }

  export function isHandwritingProduct(productType: ProductType): boolean {
    return productType === ProductType.DigitalResourcesHandwriting2020
      || productType === ProductType.DigitalResourcesHandwriting2025
      || productType === ProductType.DigitalResourcesHandwriting2020Texas
      || productType === ProductType.DigitalResourcesHandwriting2020TexasSample
      || productType === ProductType.DigitalResourcesLaEscritura2020
      || productType === ProductType.DigitalResourcesLaEscritura2020Texas
      || productType === ProductType.DigitalResourcesLaEscritura2020TexasSample;
  }

  export function isSpellingProduct(productType: ProductType): boolean {
    return productType === ProductType.DigitalResourcesSpelling2020Texas
      || productType === ProductType.DigitalResourcesSpelling2020TexasSample
      || productType === ProductType.DigitalResourcesSpelling2022
      || productType === ProductType.DigitalResourcesSpelling2016;
  }

  export function isSuperkidsProduct(productType: ProductType): boolean {
    return productType === ProductType.DigitalResourcesSuperkids2015
      || productType === ProductType.DigitalResourcesSuperkids2017
      || productType === ProductType.DigitalResourcesSuperkids2026
      || productType === ProductType.DigitalResourcesSuperkidsFsk
      || productType === ProductType.DigitalResourcesSuperkidsHea;
  }

  export function getAssetDirectoryPath(gradeIdentifier: string, componentId: string): string {
    const dir = 'content/';
    const productIdentifier = componentId ? componentId.split('_').shift().toLocaleLowerCase() : 'sk2015';
    const grade = gradeIdentifier ? mapGradeToGradeType(gradeIdentifier).toLocaleLowerCase() : 'gradek';

    const productLinerFolder = this.getProductLineFolder(productIdentifier);

    return `${dir}${grade}/${productLinerFolder}`;
  }

  export function getProductLineFolder(productLine: string): string {
    let productLineFolder = productLine;
    if (productLine.endsWith('n')) {
      productLineFolder = productLine.slice(0, -1);
    } else if (productLine.endsWith('tx')) {
      productLineFolder = productLine.slice(0, -2);
    }
    return productLineFolder;
  }

  export function convertViewerLanguageForApiAssets(language: ViewerLanguage): ViewerLanguage {
    let convertedLanguage = language;
    if (language === ViewerLanguage.en) {
      convertedLanguage = ViewerLanguage.English;
    } else if (language === ViewerLanguage.es) {
      convertedLanguage = ViewerLanguage.Spanish;
    }
    return convertedLanguage;
  }

  export function mapComponentIdToVariantType(componentId: string): VariantType {
    const variantMatches = componentId.toLocaleLowerCase().match(Helpers.componentIdVariantPattern);
    let variantIdentifier = variantMatches && variantMatches.length === 6 ? `_${variantMatches[4]}` : '';
    if (variantIdentifier && variantMatches[5]) {
      variantIdentifier = `${variantIdentifier}${variantMatches[5]}`;
    }

    const variantTypeIdentifier = VariantTypeIdentifier[variantIdentifier] || 'None';
    const variantType = VariantType[variantTypeIdentifier];
    if (!componentId.includes('2025') && (variantType === VariantType.Grade2C || variantType === VariantType.Grade2M)) {
      // Grade2C and Grade2M assets are retrieved from the Grade2 folder.
      return VariantType.Grade2;
    }
    return variantType;
  }

  export function mapComponentIdToViewerVariantType(componentId: string): VariantType {
    const variantMatches = componentId.toLocaleLowerCase().match(Helpers.componentIdVariantPattern);
    let variantIdentifier = variantMatches && variantMatches.length === 6 ? `_${variantMatches[4]}` : '';
    if (variantIdentifier && variantMatches[5]) {
      variantIdentifier = `${variantIdentifier}${variantMatches[5]}`;
    }

    const variantType = VariantTypeIdentifier[variantIdentifier] || 'None';
    return VariantType[variantType];
  }

  export function removeMarkupFromText(text: string): string {
    return text.replace(/<\/?[^>]+(>|$)/g, '');
  }
}
