import { GradeType } from './grade-type';
import { ProductType } from './product-type';
import { VariantType } from './variant-type';

export enum ViewerEdition {
  Texas = 'tx',
  National = 'ntl',
  Any = 'any',
}

export enum ViewerLanguage {
  en = 'en',
  es = 'es',
  English = 'English',
  Spanish = 'Spanish',
}

export enum ViewerProduct {
  hw = 'hw',
  laesc = 'hw',
  spcn = 'spcn',
  jiw = 'jump',
  wordh = 'wh',
  wh = 'wh',
  gum = 'gum',
  ww = 'ww',
  sk = 'sk',
  fsk = 'fsk',
  hea = 'hea',
  ksn = 'ksn',
  kenc = 'kenc',
  ece = 'ece',
}

export interface IViewerMeta {
  edition?: ViewerEdition;
  language?: ViewerLanguage;
  series?: string;
  /**
   * The grade designation of the asset. Can be None.
   */
  grade?: GradeType;
  /**
   * The variant designation of the asset. Can be None.
   */
  variant?: VariantType;
  /**
   * The variant designation of the asset needed for the viewer api. Can be None.
   */
  viewerVariant?: VariantType;
  product?: ProductType;
  topicKey?: string;
}

export const getEditionFromValue = (value: string): ViewerEdition => {
  if (value === 'n') {
    return ViewerEdition.National;
  }
  if (value === 'tx') {
    return ViewerEdition.Texas;
  }

  return null;
};
