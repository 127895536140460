import { Injectable } from '@angular/core';

import { ActivityProduct } from '@models/activity-product';
import { ApiResponse } from '@models/api-response';
import { IBootstrapConfiguration } from '@models/bootstrap-config';
import { ChallengeType } from '@models/challenge-type';
import { ProductType, ProductTypeMap } from '@models/product-type';
import { TopicType } from '@models/topic-type';
import { IUser } from '@models/user';
import { ApplicationUser } from '@models/user/application-user';
import { IStudentProfile } from '@models/user/profile-detail';
import { IUserStatusPartial } from '@models/user/user-status';
import { UserPreferences } from '@models/user-preferences';
import { CompanyCodeEnum } from '@shared/enums/company-code-enum';
import { Helpers } from '@shared/helpers';
import { Level } from '@shared/level.enum';
import { Products } from '@shared/products.enum';
import * as _ from 'lodash';
import { interval, BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import * as storejs from 'store/dist/store.modern';
import * as localStorage from 'store/storages/localStorage';
import { environment } from '../../environments/environment';

import { AuthService } from './auth.service';

@Injectable()
export class BootstrapService {
  private _componentId: string;
  private _activity: string;
  private _baseUrl: string = '/sites/digital-resources';
  private _classroomId: string;
  private _grade: string;
  private _level: Level;
  private _language: string;
  private _assetId: string;
  private _mediaUrl: string = '/';
  private _frontEndUrl: string = '/';
  private _product: Products;
  private _productType: string;
  private _activityId: string;
  private _activityProduct: ActivityProduct;
  private _skipIntro: boolean;
  private _unit: string;
  private _user: IUser;
  private _hasToken: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  private _intervalSubscription: Subscription;
  private _topicKey: string;
  private _companyCode: CompanyCodeEnum;
  hasAudioEnabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private readonly activityMatrix: { [key: string]: ActivityProduct } = {
    spelling_concentration: { product: 'spelling', subProduct: 'concentration' },
    spelling_pretest: { product: 'spelling', subProduct: 'test' },
    spelling_posttest: { product: 'spelling', subProduct: 'test' },
    spelling_practice: { product: 'spelling', subProduct: 'test' },
    word_sort: { product: 'spelling', subProduct: 'word-sort' },
    spelling_timed: { product: 'spelling', subProduct: 'crack-the-safe' },
    spelling_untimed: { product: 'spelling', subProduct: 'crack-the-safe' },
    spelling_hangman: { product: 'spelling', subProduct: 'save-the-spaceship' },
    spelling_proofreading: { product: 'spelling', subProduct: 'the-honey-times' },
    spelling_unscramble: { product: 'spelling', subProduct: 'bumbling-bee' },
    spelling_completion: { product: 'spelling', subProduct: 'tomb-explorer' },
    spelling_pattern: { product: 'spelling', subProduct: 'pattern-lesson' },
  };

  private _initialized: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  get hasToken(): BehaviorSubject<boolean> {
    return this._hasToken;
  }

  get apiToken(): string {
    const store = storejs.createStore([localStorage]);
    const token = store.get('coreApiToken');
    return (token) || null;
  }

  set apiToken(value: string) {
    const store = storejs.createStore([localStorage]);
    store.set('coreApiToken', value);
  }

  get componentId(): string { return this._componentId; }

  /** Grade */
  get grade(): string {
    if (this._grade) {
      return this._grade;
    }
    return null;
  }
  /** Activity to Start */
  get activity(): string { return this._activity; }
  /** Set the activity to start */
  set activity(value: string) { this._activity = value; }
  /** ClassroomId (Guid) - ZB Portal */
  get classroomId(): string { return this._classroomId; }
  /** Language code - ZB Portal */
  get language(): string { return this._language ? this._language : 'en'; }
  /** Asset Id for Viever - ZB Portal */
  get assetId(): string { return this._assetId; }
  /** ActivityId (Guid) - ZB Portal */
  get activityId(): string { return this._activityId; }
  /** Get the activity name from the activity matrix */
  get activityProduct(): ActivityProduct {
    if (this._activityProduct) {
      return this._activityProduct;
    }
    const activityString = this._activity;
    const info = this.activityMatrix[activityString];
    if (info) {
      return info;
    }
    return null;
  }
  /** Unit of Study */
  get unit(): string { return this._unit; }
  /** Level of Difficulty for the Unit */
  get level(): Level { return this._level; }
  /** @todo CDN URL for /content assets */
  get mediaUrl(): string { return this._mediaUrl; }

  /**
   * Sets the media URL based on ZBPortal-Api.
   *
   * There should only be one CDN assetUrl for a particular environment at this time.
   *
   * @param {String} assetUrl the media url from ZBPortal-Api.
   */
  set mediaUrl(assetUrl: string) {
    this._mediaUrl = assetUrl;
  }

  /** front end URL for other front end projects */
  get frontEndUrl(): string { return this._frontEndUrl; }
  /** indicates if there is an assignment associated with this session, otherwise independent work (or free play) */
  get hasAssignment(): boolean { return false; }
  /** indicates if the boostrap services has been initialized */
  get isInitialized(): Observable<boolean> { return this._initialized.asObservable(); }
  /** indicates if the current user is a student */
  get isStudent(): boolean {
    return this._user.isStudent;
  }
  /** indicates if the current user is a teacher */
  get isTeacher(): boolean {
    return this._user.isTeacher;
  }
  /** indicates if the current user is a parent */
  get isParent(): boolean {
    return this._user.isParent;
  }
  /** indicates if the current user is a school admin */
  get isSchoolAdmin(): boolean {
    return this._user.isSchoolAdmin;
  }
  /** indicates if the intros should be skipped. Used for game replay */
  get skipIntro(): boolean { return this._skipIntro; }
  /** indicates what product  */
  get product(): Products { return this._product; }
  /** indicates what product type, special case for TX */
  get productType(): string { return this._productType; }
  /** Base url of the environment */
  get baseUrl(): string {
    // Overrides base url when launching via Angular dev server port.
    return this.isLocalhost || ['4200', '4300'].includes(window.location.port) ? '' : this._baseUrl;
  }
  /** Returns true for localhost, local.my and int environments **/
  get isLocalUrl(): boolean {
    const { hostname } = window.location;
    return !!(_.includes(hostname, 'localhost')
      || _.includes(hostname, 'stage-zbportal')
      || _.includes(hostname, '10.0.2.2'));
  }
  get isLocalhost(): boolean {
    return _.includes(window.location.hostname, 'localhost');
  }
  get isInternalHost(): boolean {
    return ['zbportal.internal', 'highlightsportal.internal'].includes(window.location.hostname);
  }
  /** Returns true for anything but production. Should only be used rarely for Q/A. */
  get isPreProductionUrl(): boolean {
    const { hostname } = window.location;
    return hostname.match(/(local|dev|staging|stage|triage|localhost)/) !== null;
  }
  get needsToken(): boolean {
    return !this.isLocalhost && !!this.product && this.product !== Products.superkids;
  }

  get bootstrapBackendUrl(): string {
    return environment.apiUrl;
  }

  get topicKey(): string { return this._topicKey; }

  get companyCode(): string { return this._companyCode; }

  set preferences(value: UserPreferences) {
    // @todo
    // this.bootstrapData.user.preferences = _.merge(this.bootstrapData.user.preferences, value);
  }

  /** Returns the user preferences object if it exists. */
  get preferences(): UserPreferences {
    return {} as UserPreferences;
  }

  set user(value: IUser) {
    // @todo
    this._user = value;
  }

  /** Returns the user. */
  get user(): IUser {
    // @todo
    return this._user;
  }

  get activityTitle(): string {
    let activityType: string = 'Activity';
    if (this.activity) {
      if (this.activity.includes('pretest')) {
        activityType = ChallengeType.Pretest;
      } else if (this.activity.includes('posttest')) {
        activityType = ChallengeType.Posttest;
      } else if (this.activity.includes('test')) {
        activityType = ChallengeType.Test;
      } else if (this.activity.includes('_Pr')) {
        activityType = TopicType.Practice;
      } else if (this.activity.includes('_Ga')) {
        activityType = TopicType.Game;
      } else if (this.activity.includes('_Wr')) {
        activityType = TopicType.Writing;
      } else if (this.activity.includes('_Le')) {
        activityType = TopicType.Lesson;
      }
    }

    return this.unit === 'R' || this.unit === '0'
      ? `Review Unit ${activityType}`
      : `Unit ${this.unit} ${activityType}`;
  }

  constructor(private authService: AuthService) { }

  /**
   * initializes the bootstrap service
   * @returns true to indicate success, otherwise false
   */
  initialize(config: IBootstrapConfiguration): Observable<boolean> {
    const {
      componentId,
      activityId,
      classroomId,
      activity,
      product,
      productType,
      activityProduct,
      unit,
      level,
      grade,
      assetId,
      language,
      skipIntro,
      topicKey,
      companyCode
    } = config;
    this._skipIntro = skipIntro;
    this._classroomId = classroomId;
    this._activityId = activityId;
    this._language = language;
    this._componentId = componentId;
    this._assetId = assetId;
    this._product = product;
    this._productType = productType;
    this._activityProduct = activityProduct;
    this._topicKey = topicKey;
    this._companyCode = companyCode;
    this.initializeTimeout();
    this.checkUserStatus()
      .subscribe((res: ApiResponse<IUserStatusPartial>) => {
        if (res.success) {
          this.user = new ApplicationUser({ ...res.response.user });
          this.mediaUrl = res.response.assetUrl;
          this._frontEndUrl = res.response.frontEndUrl;
          this.apiToken = res.response.token;
          this.completeInitialization(this.initializeDefault(activity, unit, level, grade));
          if (this.user.isStudent) {
            this.getStudentUserProfile();
          }
        } else {
          this.user = null;
          this.apiToken = null;
          this.completeInitialization(false);
        }
      });

    return this.isInitialized;
  }

  private initializeDefault(activity: string, unit: string, level: string, grade: string = null): boolean {
    if (activity && unit) {
      this.setupUsingParameters(activity, unit, level);
    } else if (activity) {
      this._activity = activity;
    }

    this._grade = grade;
    return true;
  }

  private setupUsingParameters(activity: string, unit: string, level: string) {
    this._activity = activity;
    this._unit = unit;
    this._level = Level[level] || Level.OnLevel; // default to on_level if not provided
  }

  private completeInitialization(success: boolean): void {
    this._initialized.next(success);
  }

  public isLocal(): boolean {
    return this.isLocalhost || this.isInternalHost;
  }

  public getSuperkidsAssetUrlByFile(filename: string): string {
    const typeMatches = filename ? filename.match(/\.([a-z0-9]+)$/i) : null;
    if (typeMatches && typeMatches.length > 1) {
      const ext = typeMatches[1].toLocaleLowerCase();
      let dir = Helpers.getAssetDirectoryPath(this._grade, this._componentId);

      if (ext === 'png' || ext === 'jpg') {
        dir += '/viewer/image/';
      } else if (ext === 'mp3' || ext === 'aac') {
        dir += '/viewer/media/';
      }
      return `${this._mediaUrl}${dir}${filename}`;
    }
    return '';
  }

  checkUserStatus(): Observable<ApiResponse<IUserStatusPartial>> {
    return this.authService.getUserStatus();
  }

  acceptAssessmentVideo(name: string): Observable<ApiResponse<boolean>> {
    return this.authService.acceptAssessmentVideo(name);
  }

  getStudentUserProfile(): void {
    this.authService.getStudentProfile()
      .subscribe((res: ApiResponse<IStudentProfile>) => {
        if (res.success) {
          this.hasAudioEnabled$.next(res.response.enableStudentAudioPrompts);
        } else {
          this.hasAudioEnabled$.next(false);
        }
      });
  }

  getProductType(): ProductType {
    if (this.productType) {
      return ProductType[this.productType];
    }
    const componentId = this.componentId ? this.componentId.toLocaleLowerCase() : '';
    const componentSegments = componentId.split('_').map(s => s.toLocaleUpperCase());
    if (componentSegments && componentSegments.length > 0) {
      return ProductTypeMap[componentSegments[0]]
        ? ProductTypeMap[componentSegments[0]]
        : ProductType.None;
    }
    return ProductType.None;
  }

  private initializeTimeout(): void {
    this._intervalSubscription = interval(2500)
      .pipe(
        map(() => {
          const token = this.apiToken;
          return !!((token && token.length > 0));
        }),
      )
      .subscribe((hasToken) => {
        this._hasToken.next(hasToken);
        if (!hasToken) {
          this._intervalSubscription.unsubscribe();
        }
      });
  }
}
