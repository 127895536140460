/* eslint-disable @typescript-eslint/prefer-literal-enum-member */
export enum ProductType {
  None = 'None',
  DigitalResourcesGum2021 = 'DigitalResourcesGum2021',
  DigitalResourcesSpelling2016 = 'DigitalResourcesSpelling2016',
  DigitalResourcesSpelling2020Texas = 'DigitalResourcesSpelling2020Texas',
  DigitalResourcesSpelling2020TexasSample = 'DigitalResourcesSpelling2020TexasSample',
  DigitalResourcesSpelling2022 = 'DigitalResourcesSpelling2022',
  DigitalResourcesHandwriting2016 = 'DigitalResourcesHandwriting2016',
  DigitalResourcesHandwriting2020 = 'DigitalResourcesHandwriting2020',
  DigitalResourcesHandwriting2025 = 'DigitalResourcesHandwriting2025',
  DigitalResourcesHandwriting2020Texas = 'DigitalResourcesHandwriting2020Texas',
  DigitalResourcesHandwriting2020TexasSample = 'DigitalResourcesHandwriting2020TexasSample',
  DigitalResourcesLaEscritura2020 = 'DigitalResourcesLaEscritura2020',
  DigitalResourcesLaEscritura2020Texas = 'DigitalResourcesLaEscritura2020Texas',
  DigitalResourcesLaEscritura2020TexasSample = 'DigitalResourcesLaEscritura2020TexasSample',
  DigitalResourcesSuperkids2015 = 'DigitalResourcesSuperkids2015',
  DigitalResourcesSuperkids2017 = 'DigitalResourcesSuperkids2017',
  DigitalResourcesSuperkids2026 = 'DigitalResourcesSuperkids2026',
  DigitalResourcesSuperkidsFsk  = 'DigitalResourcesSuperkidsFsk',
  DigitalResourcesSuperkidsHea = 'DigitalResourcesSuperkidsHea',
  DigitalResourcesKickstartNumberSense2021 = 'DigitalResourcesKickstartNumberSense2021',
  DigitalResourcesKickstartEarlyNumberAndCounting2023 = 'DigitalResourcesKickstartEarlyNumberAndCounting2023',
  DigitalResourcesMindscapes2024 = 'DigitalResourcesMindscapes2024',
  DigitalResourcesEce2024 = 'DigitalResourcesEce2024',
}

export enum ProductTypeMap {
  None = ProductType.None,
  GUM2021N = ProductType.DigitalResourcesGum2021,
  SPCN2016N = ProductType.DigitalResourcesSpelling2016,
  SPCN2020TX = ProductType.DigitalResourcesSpelling2020Texas,
  SPCN2020TXS = ProductType.DigitalResourcesSpelling2020TexasSample,
  SPCN2022N = ProductType.DigitalResourcesSpelling2022,
  SPCN2022 = ProductType.DigitalResourcesSpelling2022,
  HW2020N = ProductType.DigitalResourcesHandwriting2020,
  HW2020 = ProductType.DigitalResourcesHandwriting2020,
  HW2025N = ProductType.DigitalResourcesHandwriting2025,
  HW2020TX = ProductType.DigitalResourcesHandwriting2020Texas,
  HW2020TXS = ProductType.DigitalResourcesHandwriting2020TexasSample,
  LAESC2020N = ProductType.DigitalResourcesLaEscritura2020,
  LAESC2020 = ProductType.DigitalResourcesLaEscritura2020,
  LAESC2020TX = ProductType.DigitalResourcesLaEscritura2020Texas,
  LAESC2020TXS = ProductType.DigitalResourcesLaEscritura2020TexasSample,
  SK2015 = ProductType.DigitalResourcesSuperkids2015,
  SK2017 = ProductType.DigitalResourcesSuperkids2017,
  SK2026 = ProductType.DigitalResourcesSuperkids2026,
  FSK2021 = ProductType.DigitalResourcesSuperkidsFsk,
  HEA2011 = ProductType.DigitalResourcesSuperkidsHea,
  KNS2021 = ProductType.DigitalResourcesKickstartNumberSense2021,
  KENC2023 = ProductType.DigitalResourcesKickstartEarlyNumberAndCounting2023,
  MS2024 = ProductType.DigitalResourcesMindscapes2024,
  ECE2024 = ProductType.DigitalResourcesEce2024,
}
