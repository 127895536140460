export enum TopicType {
  // Generic topic types.
  Practice = 'Practice',
  Game = 'Game',
  Writing = 'Writing',
  Lesson = 'Lesson',

  // Spelling special snowflake topic types.
  WordList = 'WordList',
  Pretest = 'Pretest',
  Posttest = 'Posttest',
  // Confusingly PracticeTest is different than Pretest/Posttest for TopicType.
  PracticeTest = 'PracticeTest',
  PatternVideo = 'PatternVideo',
  PatternActivity = 'PatternActivity',
  ExtraPatternPractice = 'ExtraPatternPractice',
  WordSort = 'WordSort',

  // Handwriting special snowflake topic types.
  FreeWrite = 'FreeWrite',
  Trace = 'Trace',
}
