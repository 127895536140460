export enum RoleType {
  ApplicationAdministrator = 'ApplicationAdministrator',
  CustomerServiceAdministrator = 'CustomerServiceAdministrator',
  CustomerServiceElevatedAdministrator = 'CustomerServiceElevatedAdministrator',
  DistrictAdministrator = 'DistrictAdministrator',
  Parent = 'Parent',
  SchoolAdministrator = 'SchoolAdministrator',
  Student = 'Student',
  Teacher = 'Teacher',
}
